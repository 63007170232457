<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
::v-deep .app {
  font-family: bodyFont !important;
}
.app {
  height: 100vh;
  width: 100vw;
  font-size: 16px;
  overflow: hidden;
  font-family: bodyFont;
}
</style>
